
import Vue, { PropType } from "vue";

import { DialogCloseType, DialogI } from "@/types/dialog";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "WelcomeDialog",
  components: {
    BaseDialog,
    Btn,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  methods: {
    goToRepetition() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          action: "startTest",
        },
      });
    },
    backToLesson() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          action: "skipTest",
        },
      });
    },
  },
});
